import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg';

import './Button.scss';

function Button({ children, ...props }) {
    const { size, buttonstyle, onClick, type } = props;

    const buttonClasses = ['button'];

    if (size === 'small') {
        buttonClasses.push('button--small');
    }

    if (buttonstyle === 'hollow') {
        buttonClasses.push('button--hollow');
    }

    if (type === 'submit') {
        return (
            <button
                type='submit'
                className={buttonClasses.join(' ')}
                onClick={() => onClick()}
            >
                {children}
                <ArrowIcon />
            </button>
        );
    }
    return (
        <button
            type='button'
            className={buttonClasses.join(' ')}
            onClick={() => onClick()}
        >
            {children}
            <ArrowIcon />
        </button>
    );
}

Button.defaultProps = {
    children: [],
    props: {},
    type: 'button',
    size: '',
    buttonstyle: '',
    onClick: () => {},
};

Button.propTypes = {
    children: PropTypes.node,
    props: PropTypes.instanceOf(Object),
    type: PropTypes.string,
    size: PropTypes.string,
    buttonstyle: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
