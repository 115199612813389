import isEmpty from './isEmpty';

const getSearchParams = () =>
    window.location.hash
        ? window.location.hash.split('?')[1]
        : window.location.search.slice(1);

export default function getUrlParams() {
    const queryString = getSearchParams();
    // eslint-disable-next-line no-shadow
    const urlParams = new URLSearchParams(queryString);
    const arrayItems = {};

    if (urlParams.has('email')) {
        const email = urlParams.get('email');
        const value = email === 'true';
        arrayItems.email = value;
    }

    if (urlParams.has('opt_in')) {
        const optIn = urlParams.get('opt_in');
        const value = optIn === '1' ? '1' : '0';
        arrayItems.optIn = value;
    }

    // List will always been an interger, if it's not do not return anything
    if (urlParams.has('opt_in_list')) {
        let optInList = urlParams.get('opt_in_list');
        optInList = parseFloat(optInList);
        const value = !Number.isNaN(optInList) ? optInList : '';
        arrayItems.optInList = value;
    }

    if (urlParams.has('source')) {
        const source = urlParams.get('source');
        arrayItems.source = source;
    }

    return isEmpty(arrayItems) ? null : arrayItems;
}
