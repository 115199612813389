/* eslint-disable no-continue */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Constants from '../../constants';
import Button from '../Button/Button';
import Textblock from '../Textblock/Textblock';
import './SignUpForm.scss';

// A lot of code has been taken from https://web.mercycorps.org/samples/widget.js;

function SignUpForm({ generalData, urlParams, toggleDrawer }) {
    const [submitting, setSubmitting] = useState(false);
    const [optIn, setOptIn] = useState(false);
    const [optInList, setOptInList] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm({
        mode: 'onBlur', // "onChange"
    });
    const navigate = useNavigate();

    const jsonpSubmit = (data) => {
        // eslint-disable-next-line no-console
        const scriptTag = document.createElement('script');
        // eslint-disable-next-line no-param-reassign

        if (data.lists === false) {
            // eslint-disable-next-line no-param-reassign
            delete data.lists;
        }

        const params = new URLSearchParams(data).toString();
        scriptTag.src = `${document.querySelector('form').action}?${params}`;
        const otherTag = document.getElementsByTagName('script')[0];
        otherTag.parentNode.insertBefore(scriptTag, otherTag);
    };

    const onSubmit = (data) => {
        jsonpSubmit(data);
        setSubmitting(true);

        // Create first name localstorage
        if (data.name) {
            window.localStorage.setItem(
                Constants.MEMORY_FIRST_NAME_KEY,
                data.name
            );
        }

        toggleDrawer();
    };

    const defaultWidgetSuccess = () => {
        setSubmitting(false);
        navigate('/');
    };

    const receivedWidgetResponse = (response) => {
        if (response.result === 'success') {
            defaultWidgetSuccess();
        } else {
            // eslint-disable-next-line no-console
            console.error(response);
        }
    };
    window.receivedWidgetResponse = receivedWidgetResponse;

    const handleButtonClick = () => {
        const { lists } = getValues();

        if (urlParams) {
            if ('source' in urlParams) {
                setValue('source', urlParams.source);
            }

            if ('optIn' in urlParams) {
                setValue('opt_in', urlParams.optIn);
            }

            if ('optInList' in urlParams && lists) {
                setValue('lists', urlParams.optInList);
            }
        }
    };

    useEffect(() => {
        const urlParamsValues = urlParams;
        if (urlParamsValues) {
            setOptIn(urlParamsValues.optIn);
            setOptInList(urlParamsValues.optInList);
        }
    }, [urlParams, setOptIn, setOptInList]);

    return (
        <div className='sign-up-form'>
            <div className='sign-up-form__message'>
                <Textblock content={generalData.signup.message} />
            </div>
            <div className='sign-up-form__form'>
                {!submitting ? (
                    <form
                        name='signup'
                        method='POST'
                        action='https://web.mercycorps.org/act/'
                        acceptCharset='utf-8'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='form-item'>
                            <label htmlFor='name'>
                                <span className='label'>First Name*</span>
                                <input
                                    id='name'
                                    name='name'
                                    type='text'
                                    aria-invalid={
                                        errors.name ? 'true' : 'false'
                                    }
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                {errors.name && (
                                    <span className='field-error' role='alert'>
                                        Please enter your first name.
                                    </span>
                                )}
                            </label>
                        </div>

                        <div className='form-item'>
                            <label htmlFor='email'>
                                <span className='label'>Email*</span>
                                <input
                                    id='email'
                                    name='email'
                                    type='email'
                                    aria-invalid={
                                        errors.email ? 'true' : 'false'
                                    }
                                    {...register('email', {
                                        required: true,
                                        pattern:
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    })}
                                />
                                {errors.email && (
                                    <span className='field-error' role='alert'>
                                        Please enter a valid email address.
                                    </span>
                                )}
                            </label>
                        </div>

                        {/* <!--
						Begin opt in block.

						opt_in = 1 when we require the opt-in checkbox to be subscribed to our list

						I think it would be ideal if we could control display of this block,
						and the value in the "lists" field via a single URL paramater.
						E.g. /lgx/?opt_in_list=[integer], where that integer gets submitted via list field.

						If that URL paramter is not present, don't show this portion and don't submit
						values for 'opt_in' and 'lists' fields.

						Additional info: https://docs.actionkit.com/docs/manual/api/rest/actionprocessing.html?highlight=opt_in#parameters
						Example from blog post: https://blog.actionkit.com/author/chris/
						--> */}

                        {optIn && optInList ? (
                            <>
                                {
                                    // show a checkbox if opt_in=1
                                    // set value to the ID of your mailing list
                                    <div className='form-item'>
                                        <label htmlFor='email_opt_in'>
                                            <input
                                                type='checkbox'
                                                id='email_opt_in'
                                                name='lists'
                                                {...register('lists')}
                                            />
                                            <span className='label'>
                                                Opt in
                                            </span>
                                        </label>
                                    </div>
                                }
                                {
                                    // require an opt-in}
                                    <input
                                        type='hidden'
                                        id='opt_in'
                                        name='opt_in'
                                        {...register('opt_in')}
                                    />
                                }
                            </>
                        ) : (
                            ''
                        )}

                        {/* <!-- end opt in form block --> */}

                        {/* <!--
						The value of this hidden form field will be the
						source code passed to Actionkit when the form is submitted.
						Pulling this value from 'source' URL paramter will match existing conventions.
						--> */}
                        <input
                            type='hidden'
                            name='source'
                            {...register('source')}
                        />
                        {/* <!--
						This is the destination we'd like to be able to control via url param.
						I believe we'll provide a default page to use.
						--> */}
                        <input
                            type='hidden'
                            name='page'
                            value='Newsletter_BSD'
                            {...register('page')}
                        />

                        <input
                            type='hidden'
                            name='callback'
                            value='receivedWidgetResponse'
                            {...register('callback')}
                        />

                        <input
                            type='hidden'
                            name='utf8'
                            value='\u1234'
                            {...register('utf8')}
                        />

                        <input
                            type='hidden'
                            name='js'
                            value='1'
                            {...register('js')}
                        />

                        <Button
                            type='submit'
                            size='small'
                            onClick={() => {
                                handleButtonClick();
                            }}
                        >
                            {generalData.signup.button_label}
                        </Button>
                    </form>
                ) : (
                    <div>Submitting...</div>
                )}
            </div>
        </div>
    );
}

SignUpForm.defaultProps = {
    generalData: {},
    urlParams: {},
    toggleDrawer: () => {},
};

SignUpForm.propTypes = {
    generalData: PropTypes.instanceOf(Object),
    urlParams: PropTypes.instanceOf(Object),
    toggleDrawer: PropTypes.func,
};

export default SignUpForm;
