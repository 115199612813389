import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';

import './SocialShare.scss';

const socialIcon = (social) => {
    let icon = null;
    switch (social.icon) {
        case 'facebook':
            icon = <FacebookIcon />;
            break;

        case 'twitter':
            icon = <TwitterIcon />;
            break;

        case 'email':
            icon = <EmailIcon />;
            break;

        default:
            break;
    }

    return icon;
};

function SocialShare({ generalData }) {
    const connectData = generalData.connect;

    return (
        <div className='social-share'>
            <span className='social-share__label'>
                {connectData.social.label}
            </span>
            <ul className='social-items'>
                {connectData.social.items.map((social) => (
                    <li
                        key={social.icon}
                        className={`social-item social-item--${social.icon}`}
                    >
                        <a
                            href={social.url}
                            target='_blank'
                            rel='noreferrer'
                            className='social-item__link'
                        >
                            {socialIcon(social)}
                            <div className='visually-hidden'>
                                {social.title}
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

SocialShare.defaultProps = {
    generalData: {},
};

SocialShare.propTypes = {
    generalData: PropTypes.instanceOf(Object),
};

export default SocialShare;
