import React from 'react';
import PropTypes from 'prop-types';

import './Textblock.scss';

function Textblock({ children, ...props }) {
    const { content } = props;

    if (content) {
        return (
            <div
                className='textblock'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        );
    }
    return <div className='textblock'>{children}</div>;
}

Textblock.defaultProps = {
    children: {},
    content: '',
};

Textblock.propTypes = {
    children: PropTypes.instanceOf(Object),
    content: PropTypes.node,
};

export default Textblock;
