import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import GameModalContent from '../Card/GameCard';

import './GameModal.scss';

ReactModal.setAppElement('#root');

function GameModal({ isVisible, data, handleCloseGameModal }) {
    const customStyles = {
        content: {
            inset: '0',
            border: 0,
            background: 'none',
            borderRadius: 0,
            padding: 0,
        },
    };

    return (
        <ReactModal
            key={Math.random()}
            isOpen={Boolean(isVisible)}
            onRequestClose={handleCloseGameModal}
            shouldCloseOnOverlayClick
            style={customStyles}
            portalClassName='ReactModalPortal modal modal--game'
            aria={{
                labelledby: 'heading',
                describedby: 'full_description',
            }}
        >
            <GameModalContent data={data} closeFunc={handleCloseGameModal} />
        </ReactModal>
    );
}

GameModal.defaultProps = {
    isVisible: false,
    data: {},
    handleCloseGameModal: () => {},
};

GameModal.propTypes = {
    isVisible: PropTypes.bool,
    data: PropTypes.instanceOf(Object),
    handleCloseGameModal: PropTypes.func,
};

export default GameModal;
