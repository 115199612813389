import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg';

import './Button.scss';

function ButtonLink({ children, ...props }) {
    const { to } = props;
    const { search } = useLocation();

    const url = search ? to + search : to;

    return (
        <Link type='button' className='button' to={url}>
            {children}
            <ArrowIcon />
        </Link>
    );
}

ButtonLink.defaultProps = {
    children: [],
    props: {},
    to: '',
};

ButtonLink.propTypes = {
    children: PropTypes.node,
    props: PropTypes.instanceOf(Object),
    to: PropTypes.string,
};

export default ButtonLink;
