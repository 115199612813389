import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import ReactModal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Constants from './constants';
import Card from './components/Card/Card';
import MatchModal from './components/Modals/MatchModal';

import './MemoryGame.scss';
import cacheImages from './utils/cacheImages';

ReactModal.setAppElement('#root');

async function fetchData(id, setGameData) {
    const response = await fetch(`../../data/${id}.json`);
    const data = await response.json();
    setGameData(data);
}

// Get the correct matching modal based on the index of the choice.
const getMatchModal = (gameData, choice) => {
    const cardIndex = gameData.cards.findIndex(
        (object) => object.src === choice.src
    );
    return gameData.match_modals[cardIndex];
};

function MemoryGame({ setScore, numOfGames, generalData, score, urlParams }) {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    const { id } = useParams();
    const [gameData, setGameData] = useState([]);
    const [cards, setCards] = useState([]);
    // const [turns, setTurns] = useState(0);
    const [choiceOne, setChoiceOne] = useState(null);
    const [choiceTwo, setChoiceTwo] = useState(null);
    const [cardsMatch, setCardsMatch] = useState(false);
    // const [isIncorrect, setIsIncorrect] = useState(false);
    const [numOfCards, setNumOfCards] = useState(0);
    const [matches, setMatches] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [showMatchModal, setShowMatchModal] = useState(false);
    const [matchModalData, setMatchModalData] = useState({});
    const [finalModal, setFinalModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [cookie, setCookie] = useLocalStorage(Constants.MEMORY_GAMES_KEY, '');
    const navigate = useNavigate();

    // Shuffle cards
    const shuffleCards = useCallback(() => {
        if (gameData.length !== 0) {
            // Get Cards
            const cardImages = gameData.cards.map((card) => ({
                ...card,
                matched: false,
            }));

            // Duplicate cards so that we have cards that can be matched.
            const shuffledCards = [...cardImages, ...cardImages]
                .sort(() => Math.random() - 0.5)
                .map((card) => ({ ...card, id: Math.random() }));

            setNumOfCards(gameData.cards.length);
            setChoiceOne(null);
            setChoiceTwo(null);
            setCards(shuffledCards);
            // setTurns(0);
        }
    }, [gameData]);

    // Handle a choice
    const handleChoice = (card) =>
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card);

    // Reset board
    const resetTurn = () => {
        setChoiceOne(null);
        setChoiceTwo(null);
        // setTurns((prevTurns) => prevTurns + 1);
        setDisabled(false);
    };

    const matchesComplete = useCallback(() => {
        if (matches !== 0) {
            return matches === numOfCards;
        }
        return false;
    }, [matches, numOfCards]);

    // Open Match Modal
    const handleOpenModal = useCallback(
        (selectedModal) => {
            let btnLabel = generalData.buttons.matching_button_label;

            // Final match modal
            if (matches === numOfCards - 1) {
                /**
                 * If it's the final match modal of the final game...
                 *
                 * numOfGames is the total number of games. We want to figure out
                 * if this is the last game being played. Score is the total number
                 * of completed games that have been played. When the user sees this
                 * current modal, the score has not been updated yet. So we compare it
                 * to the total number of games - 1.
                 */
                const finalGameFinalMatchModel = score === numOfGames - 1;
                let message = '';

                if (finalGameFinalMatchModel) {
                    message = generalData.finalGameCompleted.message;
                    btnLabel = generalData.finalGameCompleted.button_label;
                } else {
                    message = generalData.completed.message;
                    btnLabel = generalData.completed.button_label;
                }

                // eslint-disable-next-line no-param-reassign
                selectedModal.showForm = true;

                // eslint-disable-next-line no-param-reassign
                selectedModal.description = selectedModal.description.concat(
                    ' ',
                    message
                );

                setFinalModal(true);
            }

            // eslint-disable-next-line no-param-reassign
            selectedModal.button = btnLabel;

            setShowMatchModal(true);
            setMatchModalData(selectedModal);
        },
        [matches, setMatchModalData, generalData, numOfCards, numOfGames, score]
    );

    // Close Match Modal
    const handleCloseMatchModal = () => {
        if (cardsMatch) {
            navigate('/');
        } else {
            setShowMatchModal(false);
        }
    };

    const addGameToCookie = useCallback(
        (game) => {
            if (cookie) {
                const cookieData = JSON.parse(cookie);

                // Does the game already exist in cookie? If not, add to cookie
                if (!cookieData.includes(game)) {
                    const gamesArray = [...cookieData, game];
                    const completed = gamesArray.length;

                    setCookie(JSON.stringify(gamesArray));
                    setScore(completed);
                }
            } else {
                const gamesArray = [game];
                const completed = gamesArray.length;
                setCookie(JSON.stringify(gamesArray));
                setScore(completed);
            }
        },
        [cookie, setCookie, setScore]
    );

    // const handleIncorrect = () => {
    //     setIsIncorrect(true);
    //     setTimeout(() => {
    //         setIsIncorrect(false);
    //     }, 1000);
    // };

    useEffect(() => {
        if (cards.length !== 0 && !cardsMatch) {
            setCardsMatch(matchesComplete());
        }
    }, [cards, cardsMatch, matchesComplete]);

    // Compare 2 selected cards
    useEffect(() => {
        if (choiceOne && choiceTwo) {
            setDisabled(true);

            // If there is a match
            if (choiceOne.src === choiceTwo.src) {
                // Update the card array with the matching cards
                setCards((prevCards) =>
                    prevCards.map((card) => {
                        if (card.src === choiceOne.src) {
                            return { ...card, matched: true };
                        }
                        return card;
                    })
                );

                setMatches((prevTurns) => prevTurns + 1);

                // eslint-disable-next-line
                setTimeout( function() {
                    const selectedModal = getMatchModal(gameData, choiceOne);
                    handleOpenModal(selectedModal);
                }, 500);

                resetTurn();
            } else {
                setTimeout(() => resetTurn(), 1000);
                // handleIncorrect();
            }
        }
    }, [
        cards,
        choiceOne,
        choiceTwo,
        gameData,
        generalData,
        handleOpenModal,
        setMatches,
    ]);

    useEffect(() => {
        if (gameData.length !== 0) {
            shuffleCards();

            const imgs = gameData.match_modals.map(
                (matchModal) => window.location.origin + matchModal.src
            );

            cacheImages(imgs, setIsLoading);
        }
    }, [gameData, shuffleCards]);

    useEffect(() => {
        if (cards.length !== 0) {
            if (cardsMatch) {
                // eslint-disable-next-line no-console
                // console.log('All cards are matched!');
                addGameToCookie(`${id}`);

                // Num of games completed so far
                // const numOfGamesPlayed = score;
            }
        }
    }, [addGameToCookie, cards, id, cardsMatch, generalData.signup, score]);

    useEffect(() => {
        // eslint-disable-next-line no-console
        fetchData(id, setGameData);
    }, [id]);

    return (
        <div className='memory-game'>
            <h1 className='visually-hidden'>Matching Game Board</h1>
            <LazyLoadImage
                alt=''
                src={gameData.bg_image_url}
                effect='blue'
                className='memory-game-bg'
            />
            <div className='container'>
                {/* <button onClick={shuffleCards} type='submit'>
                New Game
            </button> */}
                <div className='card-grid'>
                    {cards.map((card, index) => (
                        <Card
                            key={card.id}
                            card={card}
                            index={index}
                            handleChoice={handleChoice}
                            flipped={
                                card === choiceOne ||
                                card === choiceTwo ||
                                card.matched
                            }
                            matched={card.matched}
                            disabled={disabled}
                        />
                    ))}
                </div>
            </div>

            {/* <div className='wrong'>{isIncorrect ? 'WRONG' : ''}</div> */}

            {/* <p>Turns: {turns}</p> */}

            {!isLoading && (
                <MatchModal
                    isVisible={showMatchModal}
                    data={matchModalData}
                    generalData={generalData}
                    handleCloseMatchModal={handleCloseMatchModal}
                    finalModal={finalModal}
                    urlParams={urlParams}
                />
            )}
        </div>
    );
}

MemoryGame.defaultProps = {
    setScore: () => {},
    numOfGames: 0,
    generalData: {},
    score: 0,
    urlParams: {},
};

MemoryGame.propTypes = {
    setScore: PropTypes.func,
    numOfGames: PropTypes.number,
    generalData: PropTypes.instanceOf(Object),
    score: PropTypes.number,
    urlParams: PropTypes.instanceOf(Object),
};

export default MemoryGame;
