const cacheImages = async (srcArray, setIsLoading) => {
    const promises = await srcArray.map(
        (src) =>
            new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve();
                img.onerror = reject();
            })
    );

    await Promise.all(promises);

    setIsLoading(false);
};

export default cacheImages;
