import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DrawerWelcome from './components/Drawer/DrawerWelcome';
import GameModal from './components/Modals/GameModal';
import Marker from './components/Marker/Marker';
import isEmpty from './utils/isEmpty';
import Constants from './constants';
import SubmissionModal from './components/Modals/SubmissionModal';

import './home.scss';

function Home({
    homeData,
    generalData,
    score,
    numOfGames,
    setFirstName,
    urlParams,
}) {
    const [welcomeDrawerOpen, setWelcomeDrawerOpen] = useState(true);
    const [showGameModal, setShowGameModal] = useState(false);
    const [gameModalData, setGameModalData] = useState({});
    const navigate = useNavigate();

    const hasPlayed = localStorage.getItem(Constants.MEMORY_GAMES_KEY) === null;
    const firstName = localStorage.getItem(Constants.MEMORY_FIRST_NAME_KEY);
    const hasfirstName =
        localStorage.getItem(Constants.MEMORY_FIRST_NAME_KEY) === null;
    const [bouncePins, setBouncePins] = useState(false);
    const toggleBouncePins = () => {
        setBouncePins((prevState) => !prevState);
    };
    const pinsBounceClass = bouncePins ? 'bounce' : '';

    // Close Modal
    const handleCloseGameModal = () => {
        setShowGameModal(false);
    };

    useEffect(() => {
        if (!hasfirstName || !welcomeDrawerOpen) {
            document.body.classList.remove('drawer--is-open');
            setBouncePins(true);

            if (hasPlayed) {
                document.body.classList.add('dropPins');

                setTimeout(() => {
                    document.body.classList.remove('dropPins');
                }, '1500');
            } else {
                document.body.classList.remove('dropPins');
            }
        } else if (welcomeDrawerOpen) {
            document.body.classList.add('drawer--is-open');
        }
    }, [welcomeDrawerOpen, hasfirstName, hasPlayed]);

    // If all games have been completed
    useEffect(() => {
        if (score === numOfGames) {
            navigate('/complete');
        }
    }, [navigate, score, numOfGames]);

    useEffect(() => {
        async function init() {
            const firstNameCookie = await localStorage.getItem(
                Constants.MEMORY_FIRST_NAME_KEY
            );
            if (firstNameCookie) {
                setFirstName('Games Completed');
            }
        }
        init();
    }, [setFirstName]);

    useEffect(() => {
        if (score === 1) {
            document.getElementById('progress-bar').style.width = `${
                score * 25
            }%`;
        } else {
            document.getElementById('progress-bar').style.width = `${
                score * 20
            }%`;
        }

        if (score >= 1) {
            document.getElementById('logo-white').style.clipPath =
                'polygon(0 0,100% 0, 100% 100%, 0% 100%)';
        }
        if (score === 5) {
            document.getElementById('name-span').classList.add('white-text');
            document.getElementById('score-span').classList.add('white-text');
        }
    }, [score]);

    if (isEmpty(homeData)) return null;

    const drawerData = homeData.drawer;

    const games = homeData.games.map(
        (game, index) =>
            !hasfirstName && (
                <Marker
                    key={game.title}
                    game={game}
                    index={index}
                    setShowGameModal={setShowGameModal}
                    setGameModalData={setGameModalData}
                    toggleBouncePins={toggleBouncePins}
                    setBouncePins={setBouncePins}
                />
            )
    );

    const gameClass = ['section', 'section--game-selection', pinsBounceClass];

    return (
        <section className={gameClass.join(' ')}>
            <div className='section__inner'>
                {hasPlayed && (
                    <DrawerWelcome
                        drawerData={drawerData}
                        welcomeDrawerOpen={welcomeDrawerOpen}
                        setWelcomeDrawerOpen={setWelcomeDrawerOpen}
                        generalData={generalData}
                        urlParams={urlParams}
                    />
                )}
                <div className='markers'>{games}</div>
                <GameModal
                    isVisible={showGameModal}
                    data={gameModalData}
                    handleCloseGameModal={handleCloseGameModal}
                />
                {firstName && (
                    <SubmissionModal
                        firstName={firstName}
                        generalData={generalData}
                    />
                )}
            </div>
        </section>
    );
}
Home.defaultProps = {
    homeData: {},
    generalData: {},
    score: 0,
    numOfGames: 0,
    setFirstName: () => {},
    urlParams: {},
};

Home.propTypes = {
    homeData: PropTypes.instanceOf(Object),
    generalData: PropTypes.instanceOf(Object),
    score: PropTypes.number,
    numOfGames: PropTypes.number,
    setFirstName: PropTypes.func,
    urlParams: PropTypes.instanceOf(Object),
};

export default Home;
