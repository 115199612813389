import React from 'react';
import PropTypes from 'prop-types';

import './Card.scss';

function Card({ card, handleChoice, flipped, matched, disabled, index }) {
    const handleClick = () => {
        if (!disabled) {
            handleChoice(card);
        }
    };

    const handleKeypress = (e) => {
        if (e.keyCode === 13) {
            if (!disabled) {
                handleChoice(card);
            }
        }
    };

    const classes = [
        'card',
        'card--tile',
        `${flipped ? 'is-flipped' : ''}`,
        `${matched ? 'is-matched' : ''}`,
    ];

    return (
        <div className={classes.join(' ')}>
            <div className='card-front'>
                <img
                    className='card-front__image'
                    style={{
                        objectPosition: `center ${
                            card.x_small_align && card.x_small_align
                        }`,
                    }}
                    src={card.src}
                    alt={`Front of card ${index + 1}`}
                />
            </div>
            <button
                type='submit'
                onClick={handleClick}
                onKeyPress={handleKeypress}
                className='card-back'
                disabled={flipped}
            >
                <div className='visually-hidden'>Back of card #{index + 1}</div>
            </button>
        </div>
    );
}
Card.defaultProps = {
    card: [],
    handleChoice: () => {},
    flipped: false,
    matched: false,
    disabled: false,
    index: 0,
};

Card.propTypes = {
    card: PropTypes.instanceOf(Object),
    handleChoice: PropTypes.func,
    flipped: PropTypes.bool,
    matched: PropTypes.bool,
    disabled: PropTypes.bool,
    index: PropTypes.number,
};

export default Card;
