import React from 'react';
import PropTypes from 'prop-types';

import './PlayerStat.scss';

function PlayerStat({ score, numOfGames, firstName }) {
    return (
        <div className='player-status'>
            <span className='name' id='name-span'>
                {firstName}:&nbsp;
            </span>
            <span className='score' id='score-span'>
                {score}/{numOfGames}
            </span>
        </div>
    );
}

PlayerStat.defaultProps = {
    score: 0,
    numOfGames: 0,
    firstName: '',
};

PlayerStat.propTypes = {
    score: PropTypes.number,
    numOfGames: PropTypes.number,
    firstName: PropTypes.string,
};

export default PlayerStat;
