import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Textblock from '../Textblock/Textblock';
import Button from '../Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import './modal-base.scss';
import './MatchModal.scss';
import SocialShare from '../SocialShare/SocialShare';
import Connect from '../Connect/Connect';

function MatchModal({
    isVisible,
    data,
    generalData,
    handleCloseMatchModal,
    finalModal,
    urlParams,
}) {
    const modalHeader = (
        <header className='modal-header'>
            <h3 className='modal-header__title' id='heading'>
                {data.title}
            </h3>
        </header>
    );

    const modalBody = (
        <>
            <div className='modal__image-container'>
                <LazyLoadImage
                    alt=''
                    // height={232}
                    src={data.src}
                    effect='opacity'
                    // width={470}
                    className='modal__image'
                />
            </div>
            <h2 className='modal__headline'>{data.headline}</h2>

            <div className='modal__content' id='full_description'>
                <Textblock content={data.description} />
            </div>
        </>
    );

    const defaultModalContent = (
        <>
            {modalHeader}

            <div className='modal-body'>
                {modalBody}

                <Button onClick={handleCloseMatchModal}>{data.button}</Button>
            </div>
        </>
    );

    const finalModalContent = (
        <div className='modal__inner'>
            <div className='modal__match-content'>
                {modalHeader}
                <div className='modal-body'>
                    {modalBody}
                    <Button onClick={handleCloseMatchModal}>
                        {data.button}
                    </Button>

                    <div className='sign-up-form__additional-info'>
                        <Connect
                            generalData={generalData}
                            urlParams={urlParams}
                        />
                        <SocialShare generalData={generalData} />
                    </div>
                </div>
            </div>
        </div>
    );

    const modalContent = finalModal ? finalModalContent : defaultModalContent;

    const modalClass = finalModal
        ? 'modal--match modal--match-final'
        : 'modal--match';

    return (
        <ReactModal
            className={modalClass}
            closeTimeoutMS={500}
            isOpen={Boolean(isVisible)}
            onRequestClose={handleCloseMatchModal}
            shouldCloseOnOverlayClick={false}
            aria={{
                labelledby: 'heading',
                describedby: 'full_description',
            }}
            style={{
                overlay: {
                    position: 'fixed',
                    backgroundColor: 'transparent',
                },
            }}
        >
            {modalContent}
            <button
                className='btn-modal-close'
                onClick={handleCloseMatchModal}
                type='button'
            >
                <CloseIcon />
                <span className='visually-hidden'>Close Match Modal</span>
            </button>
        </ReactModal>
    );
}

MatchModal.defaultProps = {
    isVisible: false,
    data: {},
    generalData: {},
    handleCloseMatchModal: () => {},
    finalModal: false,
    urlParams: {},
};

MatchModal.propTypes = {
    isVisible: PropTypes.bool,
    data: PropTypes.instanceOf(Object),
    generalData: PropTypes.instanceOf(Object),
    handleCloseMatchModal: PropTypes.func,
    finalModal: PropTypes.bool,
    urlParams: PropTypes.instanceOf(Object),
};

export default MatchModal;
