import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Textblock from './components/Textblock/Textblock';
import isEmpty from './utils/isEmpty';
import setSource from './utils/setSource';
import { ReactComponent as ArrowIcon } from './assets/icons/button-arrow.svg';

import bgImageLarge from './assets/img/nepal-202111-emillstein-7761.jpg';
import bgImageLargeSmall from './assets/img/nepal-202111-emillstein-7761-mobile.jpg';
import SocialShare from './components/SocialShare/SocialShare';

import './Complete.scss';

function Complete({ generalData, urlParams }) {
    const [completeData, setCompleteData] = useState({});

    useEffect(() => {
        axios.get('../data/complete.json').then((response) => {
            setCompleteData(response.data);
        });
    }, []);

    useEffect(() => {
        document.getElementById('progress-bar').style.width = '100%';
        document.getElementById('logo-white').style.clipPath =
            'polygon(0 0,100% 0, 100% 100%, 0% 100%)';
        document.getElementById('name-span').classList.add('white-text');
        document.getElementById('score-span').classList.add('white-text');
    }, []);

    if (isEmpty(generalData) || isEmpty(completeData)) return null;

    return (
        <section className='section section--complete'>
            <picture className='bg-image__container'>
                <source media='(min-width: 768px)' srcSet={bgImageLarge} />
                <source srcSet={bgImageLargeSmall} />
                <img
                    className='bg-image__image'
                    src={bgImageLargeSmall}
                    alt='Nepal'
                />
            </picture>
            <div className='completed-message'>
                <h1 className='completed-message__title'>
                    {completeData.title}
                </h1>
                <Textblock content={completeData.content} />
                <ul className='completed-message__links links'>
                    {generalData?.connect?.links.map((link) => {
                        const finalUrl =
                            urlParams && urlParams.source
                                ? setSource({
                                      link: link.url,
                                      paramName: 'source',
                                      paramValue: urlParams.source,
                                  })
                                : link.url;
                        return (
                            <li key={link.title}>
                                <a
                                    className='button'
                                    href={finalUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {link.title}
                                    <ArrowIcon />
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <div className='completed-message__social-links'>
                    <SocialShare generalData={generalData} />
                </div>
            </div>
        </section>
    );
}

Complete.defaultProps = {
    generalData: {},
    urlParams: {},
};

Complete.propTypes = {
    generalData: PropTypes.instanceOf(Object),
    urlParams: PropTypes.instanceOf(Object),
};

export default Complete;
