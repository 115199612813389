import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import PlayerStat from '../PlayerStat/PlayerStat';
import logo from '../../assets/img/logo.svg';
import logoWhite from '../../assets/img/logo-white.svg';
import setSource from '../../utils/setSource';

import './SiteHeader.scss';

function SiteHeader({ generalData, score, numOfGames, firstName, urlParams }) {
    const { pathname } = useLocation();
    const logoUrl =
        urlParams && urlParams.source
            ? setSource({
                  link: 'https://www.mercycorps.org',
                  paramName: 'source',
                  paramValue: urlParams.source,
              })
            : 'https://www.mercycorps.org?source=N1ZWPMSTLMNZZ0630231&utm_source=lgx&utm_medium=organic&utm_campaign=lgx';

    return (
        <header className='app-header'>
            <span id='progress-bar' />
            <div className='site-branding'>
                <a href={logoUrl} target='_blank' rel='noreferrer'>
                    <img src={logo} alt='Mercy Corps Logo' />
                    <img
                        id='logo-white'
                        src={logoWhite}
                        alt='Mercy Corps Logo'
                    />
                </a>
            </div>
            {score === 0 && pathname === '/' && (
                <div className='instructions'>{generalData.instructions}</div>
            )}
            {score > 0 && (
                <PlayerStat
                    score={score}
                    numOfGames={numOfGames}
                    firstName={firstName}
                />
            )}
        </header>
    );
}

SiteHeader.defaultProps = {
    generalData: {},
    score: 0,
    numOfGames: 0,
    firstName: '',
    urlParams: {},
};

SiteHeader.propTypes = {
    generalData: PropTypes.instanceOf(Object),
    score: PropTypes.number,
    numOfGames: PropTypes.number,
    firstName: PropTypes.string,
    urlParams: PropTypes.instanceOf(Object),
};

export default SiteHeader;
