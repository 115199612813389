import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Textblock from '../Textblock/Textblock';
import Constants from '../../constants';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import './SubmissionModal.scss';

ReactModal.setAppElement('#root');

function SubmissionModal({ firstName, generalData }) {
    const [showModal, setShowModal] = useState(true);
    const [wasOpened, setWasOpened] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    let { title } = generalData.signup_success;
    title = title.replace('%s', firstName);

    useEffect(() => {
        const messageCookie = localStorage.getItem(
            Constants.MEMORY_SIGNED_UP_MESSAGE_KEY
        );

        if (!messageCookie) {
            setWasOpened(true);
            localStorage.setItem(Constants.MEMORY_SIGNED_UP_MESSAGE_KEY, 1);
        }
    }, []);

    if (!wasOpened) {
        return null;
    }

    return (
        <ReactModal
            isOpen={showModal}
            contentLabel='Form submitted successfully'
            portalClassName='modal modal--successful-submission'
            className='modal__content'
            shouldCloseOnOverlayClick
            onRequestClose={handleClose}
            aria={{
                labelledby: 'success-modal-heading',
                describedby: 'success-modal-message',
            }}
        >
            <button className='btn-close' type='button' onClick={handleClose}>
                <CloseIcon />
                <span className='visually-hidden'>
                    Close submission success modal
                </span>
            </button>

            <h2
                className='modal__title'
                id='success-modal-heading'
            >{`${title}`}</h2>
            <div className='modal__message' id='success-modal-message'>
                <Textblock content={generalData.signup_success.message} />
            </div>
        </ReactModal>
    );
}

SubmissionModal.defaultProps = {
    firstName: '',
    generalData: {},
};

SubmissionModal.propTypes = {
    firstName: PropTypes.string,
    generalData: PropTypes.instanceOf(Object),
};

export default SubmissionModal;
