import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import Textblock from '../Textblock/Textblock';
import Constants from '../../constants';
import SignUpForm from '../SignUpForm/SignUpForm';

import 'rc-drawer/assets/index.css';
import './DrawerWelcome.scss';

function DrawerWelcome({
    drawerData,
    welcomeDrawerOpen,
    setWelcomeDrawerOpen,
    generalData,
    urlParams,
}) {
    const drawer = drawerData;
    const [activeTrap, setActiveTrap] = useState(false);
    // const currentInput = useWhatInput('input');
    const wrapperRef = useRef(null);

    const toggleDrawer = () => {
        setWelcomeDrawerOpen(false);
        setActiveTrap(false);

        // Remove Drawer
        setTimeout(() => {
            const $drawer = document.querySelector('.drawer--welcome');
            if ($drawer) {
                $drawer.remove();
            }
        }, 500);
    };

    useEffect(() => {
        // Has a user signed up?
        const hasfirstName =
            localStorage.getItem(Constants.MEMORY_FIRST_NAME_KEY) === null;

        setWelcomeDrawerOpen(hasfirstName);
    }, [setWelcomeDrawerOpen]);

    return (
        <FocusTrap active={activeTrap}>
            <div
                className='drawer drawer--welcome'
                data-open={welcomeDrawerOpen}
                ref={wrapperRef}
            >
                <div className='drawer__inner'>
                    <h1 className='drawer__title'>{drawer.title}</h1>
                    <div className='drawer__body'>
                        <Textblock content={drawer.description} />
                        <div className='modal__match-signup'>
                            <SignUpForm
                                generalData={generalData}
                                urlParams={urlParams}
                                toggleDrawer={toggleDrawer}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
}

DrawerWelcome.defaultProps = {
    drawerData: [],
    welcomeDrawerOpen: false,
    setWelcomeDrawerOpen: () => {},
    generalData: {},
    urlParams: {},
};

DrawerWelcome.propTypes = {
    drawerData: PropTypes.instanceOf(Object),
    welcomeDrawerOpen: PropTypes.bool,
    setWelcomeDrawerOpen: PropTypes.func,
    generalData: PropTypes.instanceOf(Object),
    urlParams: PropTypes.instanceOf(Object),
};

export default DrawerWelcome;
