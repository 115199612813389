import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';
import { Popover } from 'react-tiny-popover';
import MatchMediaWrapper from '../../utils/MatchMediaWrapper';
import GameModalContent from '../Card/GameCard';
import cacheImages from '../../utils/cacheImages';
import { ReactComponent as MapPin } from '../../assets/icons/map-pin.svg';
import Constants from '../../constants';

import './Marker.scss';

function Marker({
    game,
    index,
    setShowGameModal,
    setGameModalData,
    toggleBouncePins,
    setBouncePins,
}) {
    const [gameData, setGameData] = useState({});
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [activePopover, setActivePopover] = useState();
    const [activeMarker, setActiveMarker] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({});
    const markerClass = ['marker', `marker--${index}`, `marker--${game.slug}`];
    const memoryGames = localStorage.getItem(Constants.MEMORY_GAMES_KEY);
    const [isLoading, setIsLoading] = useState(true);

    if (memoryGames) {
        const memGameArray = JSON.parse(memoryGames);
        memGameArray.forEach((memgame) => {
            const indexBounce = markerClass.indexOf('bounce');
            if (game.slug === memgame) {
                markerClass.push('is-complete');
                if (indexBounce > -1) {
                    markerClass.splice(indexBounce, 1);
                }
            }
        });
    }

    markerClass.push(activeMarker ? 'is-active' : '');

    const handleMapPinActiveState = () => {
        setActiveMarker((prevState) => !prevState);
    };

    const handlePopoverClose = useCallback(() => {
        setIsPopoverOpen(!isPopoverOpen);
        activePopover.focus();
        setBouncePins(true);
    }, [isPopoverOpen, activePopover, setBouncePins]);

    const mobileContent = (
        <button
            type='button'
            className={markerClass.join(' ')}
            style={markerPosition}
            onClick={() => {
                setShowGameModal(true);
                setGameModalData(gameData);
                toggleBouncePins();
            }}
        >
            <MapPin />
            <span className='visually-hidden'>{game.title}</span>
        </button>
    );
    const desktopContent = (
        <Popover
            isOpen={isPopoverOpen}
            align='start'
            positions={['left', 'right']}
            reposition
            parentElement={document.querySelector('.app-main')}
            boundaryInset={10}
            padding={10}
            onClickOutside={() => {
                setIsPopoverOpen(false);
                handleMapPinActiveState();
                setBouncePins(true);
            }} // handle click events outside of the popover/target here!
            // eslint-disable-next-line react/no-unstable-nested-components
            content={() => (
                <div role='dialog' aria-modal='true'>
                    <GameModalContent
                        data={gameData}
                        closeFunc={handlePopoverClose}
                    />
                </div>
            )}
        >
            <button
                type='button'
                className={markerClass.join(' ')}
                style={markerPosition}
                onClick={(e) => {
                    handleMapPinActiveState();
                    setIsPopoverOpen(!isPopoverOpen);
                    setActivePopover(e.target);
                    toggleBouncePins();
                }}
            >
                <MapPin />
                <span className='visually-hidden'>{game.title}</span>
            </button>
        </Popover>
    );

    function positionMarkers() {
        const mediumBp = window.matchMedia('(min-width: 768px)').matches;
        const maxHeight = window.matchMedia('(max-height: 900px)').matches;
        const imageWidth = mediumBp ? 1440 : 375;
        const imageHeight = mediumBp ? 969 : 757;
        const imageAspectRatio = imageWidth / imageHeight;
        const $window = window;
        const windowWidth = $window.innerWidth;
        const windowHeight = $window.innerHeight;
        const windowAspectRatio = windowWidth / windowHeight;
        const yOffset = game.y_large_offset ? game.y_large_offset : 0;

        let xPos = mediumBp ? game.x_large : game.x_small;
        let yPos = mediumBp
            ? game.y_large + (maxHeight ? yOffset : 0)
            : game.y_small;

        if (windowAspectRatio > imageAspectRatio) {
            yPos = (yPos / imageHeight) * 100;
            xPos = (xPos / imageWidth) * 100;
        } else {
            yPos =
                (yPos / (windowAspectRatio / imageAspectRatio) / imageHeight) *
                100;
            xPos =
                (xPos / (windowAspectRatio / imageAspectRatio) / imageWidth) *
                100;
        }

        const style = {
            marginTop: `${yPos}%`,
            marginLeft: `${xPos}%`,
        };

        setMarkerPosition(style);
    }

    useEffect(() => {
        const modal = document.querySelector('.react-tiny-popover-container');
        const closeButton = document.querySelector(
            '.card--game .btn-modal-close'
        );
        const playButton = document.querySelector(
            '.card--game .card-body .button'
        );

        if (isPopoverOpen) {
            if (playButton) {
                setTimeout(() => playButton.focus(), 1);

                modal.addEventListener('keydown', (event) => {
                    const code = event.keyCode || event.which;
                    if (code === 9) {
                        if (playButton === document.activeElement) {
                            event.preventDefault();
                            closeButton.focus();
                        }
                    }

                    if (code === 27) {
                        if (playButton === document.activeElement) {
                            handlePopoverClose();
                        }
                    }
                });
            }
        }
    }, [isPopoverOpen, handlePopoverClose]);

    useEffect(() => {
        axios.get(`../data/${game.slug}.json`).then((response) => {
            response.data.button_url = `/memorygame/${game.slug}`;
            setGameData(response.data);
        });
    }, [game]);

    useEffect(() => {
        positionMarkers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        cacheImages(
            [window.location.origin + gameData.teaser_image],
            setIsLoading
        );
    }, [gameData]);

    const handleResize = useDebouncedCallback(() => positionMarkers(), 200);
    window.addEventListener('resize', handleResize);

    if (!isLoading) {
        return (
            <MatchMediaWrapper
                bp='1280'
                mobileContent={mobileContent}
                desktopContent={desktopContent}
            />
        );
    }
}

Marker.defaultProps = {
    game: {},
    index: 0,
    setShowGameModal: null,
    setGameModalData: null,
    toggleBouncePins: () => {},
    setBouncePins: () => {},
};

Marker.propTypes = {
    game: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    setShowGameModal: PropTypes.func,
    setGameModalData: PropTypes.func,
    toggleBouncePins: PropTypes.func,
    setBouncePins: PropTypes.func,
};

export default Marker;
