import { useState, useEffect } from 'react';

const MatchMediaWrapper = ({ bp, mobileContent, desktopContent }) => {
    const [isNarrowScreen, setIsNarrowScreen] = useState(false);
    useEffect(() => {
        const mediaWatcher = window.matchMedia(`(min-width: ${bp}px)`);
        setIsNarrowScreen(mediaWatcher.matches);

        function updateIsNarrowScreen(e) {
            setIsNarrowScreen(e.matches);
        }
        if (mediaWatcher.addEventListener) {
            mediaWatcher.addEventListener('change', updateIsNarrowScreen);
            return function cleanup() {
                mediaWatcher.removeEventListener(
                    'change',
                    updateIsNarrowScreen
                );
            };
        }
        mediaWatcher.addEventListener(updateIsNarrowScreen);
        return function cleanup() {
            mediaWatcher.removeEventListener(updateIsNarrowScreen);
        };
    }, [bp, setIsNarrowScreen]);

    return isNarrowScreen ? desktopContent : mobileContent;
};

export default MatchMediaWrapper;
