import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Textblock from '../Textblock/Textblock';
import ButtonLink from '../Button/ButtonLink';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import './CardGame.scss';

function GameModalContent({ data, closeFunc }) {
    const closeBtn = () => {
        if (closeFunc) {
            return (
                <button
                    className='btn-modal-close'
                    onClick={() => closeFunc()}
                    type='button'
                >
                    <CloseIcon />
                    <span className='visually-hidden'>Close Game Modal</span>
                </button>
            );
        }
        return '';
    };

    return (
        <div className='card card--game'>
            {closeBtn()}
            <header className='card-header'>
                <h1 id='heading' className='card-header__title'>
                    {data.name}
                </h1>
                <h2 className='card-header__location'>{data.location}</h2>
            </header>
            <div className='card-body'>
                {data.teaser_image ? (
                    <div className='card__image-container'>
                        <LazyLoadImage
                            alt=''
                            // height={232}
                            src={data.teaser_image}
                            effect='opacity'
                            // width={470}
                            className='card__image'
                        />
                    </div>
                ) : (
                    ''
                )}

                <div className='card__description' id='full_description'>
                    <Textblock content={data.description} />
                </div>
                <ButtonLink to={data.button_url}>
                    {data.button_label}
                </ButtonLink>
            </div>
        </div>
    );
}

GameModalContent.defaultProps = {
    data: {},
    closeFunc: null,
};

GameModalContent.propTypes = {
    data: PropTypes.instanceOf(Object),
    closeFunc: PropTypes.func,
};

export default GameModalContent;
