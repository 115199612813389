import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Constants from './constants';
import Home from './Home';
import MemoryGame from './MemoryGame';
import SiteHeader from './components/SiteHeader/SiteHeader';
import Complete from './Complete';
import isEmpty from './utils/isEmpty';

import './App.scss';
import getUrlParams from './utils/getUrlParams';

function App() {
    const [score, setScore] = useState(0);
    const [generalData, setGeneralData] = useState({});
    const [homeData, setHomeData] = useState({});
    const [numOfGames, setNumOfGames] = useState(0);
    const [firstName, setFirstName] = useState('Games Completed');
    const [urlParams, setUrlParams] = useState({});

    useEffect(() => {
        axios.get('../data/general.json').then((response) => {
            setGeneralData(response.data);
        });

        axios.get('../data/home.json').then((res) => {
            const { data } = res;
            setHomeData(data);
            setNumOfGames(data.games.length);
        });
        setUrlParams(getUrlParams());
    }, []);

    useEffect(() => {
        // if cookie exists
        const memoryGames = localStorage.getItem(Constants.MEMORY_GAMES_KEY);
        if (memoryGames) {
            // Get score
            const completed = JSON.parse(memoryGames).length;
            // Update score
            setScore(completed);
        }
    }, [setScore]);

    if (isEmpty(generalData) || isEmpty(homeData)) return null;

    return (
        <BrowserRouter>
            <SiteHeader
                generalData={generalData}
                score={score}
                numOfGames={numOfGames}
                firstName={firstName}
                urlParams={urlParams}
            />
            <main className='app-main'>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <Home
                                homeData={homeData}
                                generalData={generalData}
                                score={score}
                                numOfGames={numOfGames}
                                setFirstName={setFirstName}
                                urlParams={urlParams}
                            />
                        }
                    />
                    <Route
                        path='/memorygame/:id'
                        element={
                            <MemoryGame
                                setScore={setScore}
                                numOfGames={numOfGames}
                                generalData={generalData}
                                score={score}
                                urlParams={urlParams}
                            />
                        }
                    />
                    <Route
                        path='/complete'
                        element={
                            <Complete
                                generalData={generalData}
                                urlParams={urlParams}
                            />
                        }
                    />

                    <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
            </main>
        </BrowserRouter>
    );
}

export default App;
