import React from 'react';
import PropTypes from 'prop-types';
import setSource from '../../utils/setSource';

import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg';

import './Connect.scss';

function Connect({ generalData, urlParams }) {
    const connectData = generalData.connect;

    return (
        <div className='connect'>
            <ul className='links'>
                {connectData.links.map((link) => {
                    const finalUrl =
                        urlParams && urlParams.source
                            ? setSource({
                                  link: link.url,
                                  paramName: 'source',
                                  paramValue: urlParams.source,
                              })
                            : link.url;
                    return (
                        <li key={link.title}>
                            <a href={finalUrl} target='_blank' rel='noreferrer'>
                                {link.title}
                                <ArrowIcon />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

Connect.defaultProps = {
    generalData: {},
    urlParams: {},
};

Connect.propTypes = {
    generalData: PropTypes.instanceOf(Object),
    urlParams: PropTypes.instanceOf(Object),
};

export default Connect;
